import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../actions/user";
import { useNavigate } from "react-router-dom";
import toastify from "../utils/toastify";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("token");
    dispatch(logoutAction());
    toastify("You have successfully signed out");
    navigate("/");
  }, []);

  return null;
};

export default Logout;
