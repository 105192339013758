import React, { Fragment } from "react";

import Header from "../commen/Header";
import Slider from "../commen/Slider";

import Footer from "../commen/Footer";
import { useLocation } from "react-router";
import { Usercontext } from "../../context/Usercontext";

const MainLayout = ({ children }) => {
  let location = useLocation();

  return (
    <Fragment>
      <div className="landing-layer">
        <div className="container">
          <Header />
          {location.pathname === "/" ? (
            <Usercontext>
              <Slider />
            </Usercontext>
          ) : null}
        </div>
      </div>
      {children}
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
