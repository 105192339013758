import parse from "html-react-parser";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router";
import { laptopAction } from "../actions/laptop";
import _ from "lodash";

const SinglePage = () => {
  const match = useMatch("/singlepage/:id");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(laptopAction(match.params.id));
  }, []);
  const laptops = useSelector((state) => state.laptops);
  const laptop = laptops[0];

  // const laptop = useSelector((state) => state.laptop);

  return (
    <div className="container">
      <section className="term-content">
        <header>
          <h1> </h1>
        </header>
        <div className="row">
          <div className="col-12">
            <section className="term-description">
              <img
                style={{ haight: "30em", width: "30em" }}
                src={laptop._embedded["wp:featuredmedia"][0].source_url}
              />

              <h2>{laptop.slug} </h2>
              <p>{parse(laptop.content.rendered)}</p>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SinglePage;
