import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { context } from "../context/context";
import _ from "lodash";

const Login = () => {
  const user = useSelector((state) => state.user);
  const loginContext = useContext(context);
  const {
    handleSigninForm,
    setEmail,
    validator,
    email,
    setPassword,
    password,
  } = loginContext;

  return (
    <div className="text-center form-signin w-100 mt-5 mb-5 mx-auto">
      <form onSubmit={handleSigninForm}>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating mb-2">
          <input
            type="email"
            name="email"
            value={!_.isEmpty(user) ? user.user.username : ""}
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={(e) => setEmail(e.target.value)}
          />
          {validator.current.message("email", email, "required|email", {
            className: "text-danger",
          })}
          <label for="floatingInput">Email address</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="password"
            name="password"
            value={!_.isEmpty(user) ? user.user.password : ""}
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {validator.current.message("password", password, "required|min:5", {
            className: "text-danger",
          })}

          <label for="floatingPassword">Password</label>
        </div>

        <p id="noUser" className="text-white text-center"></p>
        <button className="w-100 btn btn-lg btn-primary" type="submit">
          Sign in
        </button>
      </form>
    </div>
  );
};

export default Login;
