import { useNavigate } from "react-router";
import { getLaptop } from "../services/laptopsService";

export const laptopAction = (id) => {
  return async (dispatch) => {
    const { data, status } = await getLaptop(id);

    if (status === 200) {
      await dispatch({
        type: "GET_LAPTOP",
        payload: data,
      });
    }
  };
};
