import React, { Fragment } from "react";

const Slider = () => {
  return (
    <Fragment>
      <header>
        <a href="/" className="logo">
          {/* <img src="images/logo.png" /> */}
        </a>
        <h1>Where to get a laptop </h1>
        <h2>selection, quality and customer service</h2>
        <h3>all in one</h3>
      </header>
    </Fragment>
  );
};

export default Slider;
