import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import { registerAction } from "../actions/user";

import toastify from "../utils/toastify";
import { context } from "./context";

export const Usercontext = ({ children }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [, setFrceUpdate] = useState("");
  const [password2, setPassword2] = useState("");
  const [search, setSearch] = useState("");
  const [filteredProduct, setFilteredProduct] = useState([]);

  const validator = useRef(new SimpleReactValidator());

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const laptops = useSelector((state) => state.laptops);
  const user = useSelector((state) => state.user);

  const handleSigninForm = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      console.log(user.username);
      if ((user.user.username === email) & (user.user.password === password)) {
        localStorage.setItem("token", "");
        toastify(`welcome ${user.user.username}`);
        navigate("/");
      } else {
        document.getElementById("noUser").innerHTML =
          "There is not any registered user";
      }
    } else {
      validator.current.showMessages();
      setFrceUpdate(1);
    }
  };
  const handeleRegisterForm = async (e) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      if (password === password2) {
        const user = { username: email, password };
        try {
          dispatch(registerAction(user));
          navigate("/login");
        } catch (error) {
          console.log(error);
        }
      } else {
        document.getElementById("notsame").innerHTML = "passwords doesnt match";
      }
    } else {
      validator.current.showMessages();
      setFrceUpdate(1);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setFilteredProduct(laptops);
  }, [laptops]);

  const filter = filteredProduct.filter((laptop) =>
    laptop.title.rendered
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
  );

  return (
    <context.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        password2,
        setPassword2,
        setFrceUpdate,
        handleSigninForm,
        handeleRegisterForm,
        validator,
        handleSearch,
        filter,
      }}
    >
      {children}
    </context.Provider>
  );
};
