import React from "react";

import _ from "lodash";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = () => {
  const user = useSelector((state) => state.user);
  return (
    <nav>
      <div className="row">
        <div className="col-xs-6 p-1">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </div>
        <div className="col-xs-6">
          <ul className="clientarea ">
            {!_.isEmpty(user) ? (
              <div>
                <li class="loggein ">
                  <a href="/">{`${user.user.username}`}</a>
                </li>
                <li class="logout ">
                  <a href="/logout"> Logout</a>
                </li>
              </div>
            ) : (
              <div>
                <li className="signin hidden">
                  <NavLink to="/login"> Login</NavLink> /{" "}
                  <NavLink to="/register"> Register</NavLink>
                </li>
                <li class="fa fa-user mx-3"></li>
              </div>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
