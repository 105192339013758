import { getLaptops } from "../services/laptopsService";

export const getAllLaptops = () => {
  return async (dispatch) => {
    const { data, status } = await getLaptops();

    if (status === 200) {
      await dispatch({
        type: "INIT",
        payload: data,
      });
    }
  };
};
