import React, { Fragment, useContext, useState } from "react";

import parse from "html-react-parser";
import { slice } from "../utils/slice";
import { NavLink } from "react-router-dom";
import Pagination from "./Pagination";
import { paginate } from "../utils/paginate";
import { context } from "../context/context";

const AllProducts = ({ products }) => {
  const contextp = useContext(context);
  const { filter, handleSearch } = contextp;
  console.log(filter);

  const [perPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const handelePage = (page) => {
    setCurrentPage(page);
  };
  const laptops = paginate(filter, currentPage, perPage);

  return (
    <Fragment>
      <div className="px-5 py-1  input-group">
        <input
          onChange={(e) => handleSearch(e)}
          type="text"
          name=""
          className="p-2"
          placeholder="How can I help you ? "
        />
        <button className="btn btn-success input-group-prepend">
          <i className="fa fa-search"></i>
        </button>
      </div>
      <main id="home-page ">
        <div className="container ">
          <section className="terms-items">
            <header>
              <h2>Last Products</h2>
              <NavLink to="/"> Back to home</NavLink>
            </header>
            <div className="row justify-content-start">
              {/* map in laptops */}
              {laptops.map((laptop) => (
                <article
                  key={laptop.id}
                  className="myArticle mb-4 col-lg-3 col-md-4 col-sm-6 col-xs-12 term-col "
                >
                  <div className="card">
                    <div style={{ height: "18rem" }}>
                      <img
                        src={laptop._embedded["wp:featuredmedia"][0].source_url}
                        className="card-img-top"
                        alt={laptop.slug}
                      />
                    </div>

                    <div className="card-body" style={{ height: "15rem" }}>
                      <h5 className="card-title font-weight-bold">
                        {parse(slice(laptop.title.rendered, 73))}
                      </h5>
                      <p className="card-text text-secondary">
                        {parse(slice(laptop.content.rendered, 120))}
                      </p>
                      <a
                        className="mybtn btn btn-success"
                        href={`/SinglePage/${laptop.id}`}
                      >
                        view product
                      </a>
                    </div>
                  </div>
                </article>
              ))}
              {/* end map */}
            </div>
          </section>

          <hr />
          <Pagination
            totalLaptops={products.length}
            currentPage={currentPage}
            perPage={perPage}
            onPageChange={handelePage}
          />
        </div>
      </main>
    </Fragment>
  );
};

export default AllProducts;
