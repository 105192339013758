import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="bottom-footer">
        <div className="container">
          <p>Design and Developed By Pedro </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
