import { loginUser, registerUser } from "../services/userServices";
import { redirect, useNavigate } from "react-router";
import toastify from "../utils/toastify";

export const registerAction = (user) => {
  return async (dispatch) => {
    dispatch({
      type: "REGISTER_USER",
      payload: { user },
    });
  };
};
export const logoutAction = () => {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_USER",
      payload: "",
    });
  };
};
