import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastify = (data) => {
  return toast(`${data}`, {
    position: "top-right",
    closeOnClick: "true",
  });
};

export default toastify;
