import React from "react";

import { range } from "lodash";

const Pagination = ({ totalLaptops, currentPage, perPage, onPageChange }) => {
  const count = Math.ceil(totalLaptops / perPage);

  const pages = range(1, count + 1);

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a
              style={{ cursor: "pointer" }}
              className="page-link"
              onClick={() => onPageChange(page)}
            >
              {page}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
