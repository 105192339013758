import { configureStore } from "@reduxjs/toolkit";

import { getAllLaptops } from "../actions/laptops";
import { laptopReducer } from "../reduccers/laptop";
import { laptopsReducer } from "../reduccers/laptops";
import { userReducer } from "../reduccers/user";

export const store = configureStore({
  reducer: {
    laptops: laptopsReducer,
    laptop: laptopReducer,
    user: userReducer,
  },
});

store.dispatch(getAllLaptops());
// store.subscribe(() => console.log(store.getState()));
