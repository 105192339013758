import http from "./httpService";
import config from "./config.json";

export const getLaptops = () => {
  return http.get(`${config.mobileApi}/wp-json/wp/v2/posts?_embed`);
};

export const getLaptop = (id) => {
  return http.get(`${config.mobileApi}/wp-json/wp/v2/posts/${id}?_embed`);
};
