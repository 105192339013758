import { createContext } from "react";

export const context = createContext({
  email: "",
  setEmail: () => {},
  password: "",
  setPassword: () => {},
  setForceUpdate: () => {},
  handleSigninForm: () => {},
  handeleRegisterForm: () => {},
  filteredProduct: "",
  setFilteredProduct: () => {},
  search: "",
  setSearch: () => {},
  handleSearch: () => {},
  filter: [],
});
