import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { context } from "../context/context";

const Register = () => {
  const registerContext = useContext(context);
  const {
    email,
    setEmail,
    password,
    setPassword,
    password2,
    setPassword2,
    validator,
    handeleRegisterForm,
  } = registerContext;
  return (
    <div className="text-center form-signin w-100 mt-5 mb-5 mx-auto">
      <form onSubmit={handeleRegisterForm}>
        <h1 className="h3 mb-3 fw-normal">Please register</h1>

        <div className="form-floating mb-2">
          <input
            name="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={(e) => setEmail(e.target.value)}
          />
          {validator.current.message("password", password, "required|min:5", {
            className: "text-danger",
          })}
          <label for="floatingInput">Email</label>
        </div>
        <div className="form-floating mb-2">
          <input
            type="password"
            name="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {validator.current.message("password", password, "required|min:5", {
            className: "text-danger",
          })}
          <label for="floatingPassword">Password</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="password"
            name="password2"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => {
              document.getElementById("notsame").innerHTML = "";
              setPassword2(e.target.value);
            }}
          />
          {validator.current.message("password2", password2, "required|min:5", {
            className: "text-danger",
          })}
          {<p id="notsame" className="text-danger"></p>}
          <label for="floatingPassword">Password</label>
        </div>
        <p className=" text-start mt-2">
          <Link className="text-white" to="/login">
            I have account
          </Link>
        </p>
        <button className="w-100 btn btn-lg btn-primary" type="submit">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
