import parse from "html-react-parser";

import React, { Fragment, useContext } from "react";
import { context } from "../context/context";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { paginate } from "../utils/paginate";
import { slice } from "../utils/slice";
import { laptopAction } from "../actions/laptop";
import { useDispatch } from "react-redux";
import { computeHeadingLevel } from "@testing-library/react";

const Products = () => {
  const contextp = useContext(context);
  const { filter, handleSearch } = contextp;

  const fproducts = paginate(filter, 1, 8);

  return (
    <Fragment>
      <div className="px-5 py-1  input-group">
        <input
          onChange={(e) => handleSearch(e)}
          type="text"
          name=""
          className="p-2"
          placeholder="How can I help you ? "
        />
        <button className="btn btn-success input-group-prepend">
          <i className="fa fa-search"></i>
        </button>
      </div>
      <main id="home-page">
        <div className="container">
          <section className="terms-items">
            <header>
              <h2>Last Products</h2>
              <NavLink to="/allproducts"> See all Products</NavLink>
            </header>
            <div className="row justify-content-center">
              {/* map in laptops */}
              {fproducts.map((laptop) => (
                <article
                  key={laptop.id}
                  className=" mb-4 col-lg-3 col-md-4 col-sm-12 "
                >
                  <div className="card">
                    <div style={{ height: "13rem" }}>
                      <img
                        src={laptop._embedded["wp:featuredmedia"][0].source_url}
                        className="card-img-top"
                        alt={laptop.slug}
                      />
                    </div>

                    <div className="card-body mt-5" style={{ height: "15rem" }}>
                      <h5 className="card-title font-weight-bold">
                        {parse(slice(laptop.title.rendered, 73))}
                      </h5>
                      <p className="card-text text-secondary">
                        {parse(slice(laptop.content.rendered, 120))}
                      </p>
                      <Link
                        className="mybtn btn btn-success"
                        to={`/singlepage/${laptop.id}`}
                      >
                        view product
                      </Link>
                    </div>
                  </div>
                </article>
              ))}
              {/* end map */}
            </div>
          </section>

          <hr />
        </div>
      </main>
    </Fragment>
  );
};

export default Products;
