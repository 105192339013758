import React from "react";

import Login from "./components/Login";
import MainLayout from "./components/layouts/MainLayout";
import Products from "./components/Products";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import { Usercontext } from "./context/Usercontext";

import Register from "./components/Register";
import SinglePage from "./components/SinglePage";
import { useSelector } from "react-redux";

import AllProducts from "./components/AllProducts";
import Logout from "./components/Logout";

function App() {
  const laptops = useSelector((state) => state.laptops);

  return (
    <MainLayout>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Usercontext>
              <Products />
            </Usercontext>
          }
        />
        <Route
          path="/login"
          element={
            <Usercontext>
              <Login />
            </Usercontext>
          }
        />
        <Route path="/logout" element={<Logout />} />

        <Route
          path="/register"
          element={
            <Usercontext>
              <Register />
            </Usercontext>
          }
        />

        <Route
          path="/allproducts"
          element={
            <Usercontext>
              <AllProducts products={laptops} />
            </Usercontext>
          }
        />
        <Route path="/singlepage/:id" element={<SinglePage />} />
      </Routes>

      <ToastContainer />
    </MainLayout>
  );
}

export default App;
